@font-face {
    font-family: "Gilroy";
    font-weight: 400;
    font-style: normal;
    src:
            local("Gilroy-Regular"),
            url("https://d2vu40klajma73.cloudfront.net/Gilroy-Regular.woff2") format("woff2"),
            url("https://d2vu40klajma73.cloudfront.net/Gilroy-Regular.woff") format("woff");
}

@font-face {
    font-family: "Gilroy";
    font-weight: 600;
    font-style: normal;
    src:
            local("Gilroy-SemiBold"),
            url("https://d2vu40klajma73.cloudfront.net/Gilroy-SemiBold.woff2") format("woff2"),
            url("https://d2vu40klajma73.cloudfront.net/Gilroy-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Gilroy";
    font-weight: 700;
    font-style: normal;
    src:
            local("Gilroy-Bold"),
            url("https://d2vu40klajma73.cloudfront.net/Gilroy-Bold.woff2") format("woff2"),
            url("https://d2vu40klajma73.cloudfront.net/Gilroy-Bold.woff") format("woff");
}


html,
body,
#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    -webkit-overflow-scrolling: touch;
}

html,
body {
    margin: 0;
    padding: 0;

    /* disable dragging on the whole page (explicitly enable it on desired elements only!) */
    -webkit-user-drag: none;
}

body *:not(a):not(p), body *::after, body *::before {
    -webkit-user-drag: none;
}

h1, h2, h3, h4, h5, h6 {
    user-select: none;
}

body input:not([type='submit']),
body textarea,
body p,
body p *,
body a,
body a * {
    -webkit-user-drag: unset;
    user-select: text;
}

img {
    image-orientation: from-image;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
}

#root {
    display: flex;
    flex-flow: column;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: inherit;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: inset 0 0 0 9999px white; /* the box shadow fixes the yellow background problem*/
    transition: background-color 5000s ease-in-out 0s; /*if you wait for 80 minutes it might still turn yellow on some browsers*/
}

*:focus {
    outline: none;
}

/**
 According to caniuse dimension styling of scrollbars is at least available in all major browsers.
 see: https://caniuse.com/?search=scrollbar%20styling
 */
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.24);
    border-radius: 6px;
}

/**
 This fixes the scrollbar being invisible on the dark drawer background
 */
/*noinspection CssUnusedSymbol*/
div.mcp-MuiDrawer-paper::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 6px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

/**
 This is for newer firefox versions. thin is also 6 px width
 */
/*noinspection CssUnknownProperty*/
* {
    scrollbar-width: thin;
    scrollbar-color: darkgray rgba(1,1,1,0);
}

/* parsed emoji images class */
img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

/* key frames for animations */
@keyframes shake-animation {
    0% {
        transform: translate(0, 0);
    }
    1.78571% {
        transform: translate(5px, 0);
    }
    3.57143% {
        transform: translate(0, 0);
    }
    5.35714% {
        transform: translate(5px, 0);
    }
    7.14286% {
        transform: translate(0, 0);
    }
    8.92857% {
        transform: translate(5px, 0);
    }
    10.71429% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
