@keyframes spinner_spin {
    0% {
        -webkit-transform: rotate(0deg);
        opacity: 0.6;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        -webkit-transform: rotate(360deg);
        opacity: 0.6;
    }
}

@keyframes physics_inspired_breaking_motion {
    0% {
        opacity: 0.6;
        -webkit-transform: rotate(0deg);
    }
    5% {
        -webkit-transform: rotate(20deg);
    }
    8.25% {
        -webkit-transform: rotate(35deg);
    }
    9% {
        -webkit-transform: rotate(36deg);
    }
    9.5% {
        -webkit-transform: rotate(38deg);
    }
    10% {
        -webkit-transform: rotate(40deg);
    }
    20% {
        -webkit-transform: rotate(65deg);
    }
    22% {
        -webkit-transform: rotate(69deg);
    }
    23% {
        -webkit-transform: rotate(74deg);
    }
    25% {
        -webkit-transform: rotate(71deg);
    }
    50% {
        -webkit-transform: rotate(23deg);
    }
    55% {
        opacity: 0.6;
    }
    70% {
        -webkit-transform: rotate(-5deg);
    }
    75% {
        -webkit-transform: rotate(-6deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(0deg);
    }
}
